import React from "react";
import { graphql } from "gatsby";
import { useThemeUI } from "@theme-ui/core";

import ArticleThumbnail from "../components/commons/ArticleThumbnail";
import ThumbnailGrid from "../components/organisms/ThumbnailGrid";
import IndexPage from "../components/organisms/IndexPage";

export default function ContentHubPage({ data, pageContext }) {
  const { sanityPage, allSanityResource } = data;
  const { theme } = useThemeUI();

  const highlightedItems = 2;
  const nodes = [...allSanityResource.nodes];
  const featuredNodes =
    pageContext.skip === 0 ? nodes.splice(0, highlightedItems) : nodes;

  return (
    <IndexPage
      title={sanityPage.seoTitle || sanityPage.title}
      description={sanityPage.seoDescription}
      pathname={sanityPage.slug.current}
      page={sanityPage}
      itemCount={allSanityResource.totalCount - highlightedItems}
      pageSize={pageContext.limit}
      currentPage={Math.floor(pageContext.skip / pageContext.limit + 1)}
    >
      <ThumbnailGrid sx={{ mt: "gridGap", mb: 7 }}>
        {pageContext.skip === 0 && (
          <ThumbnailGrid.Featured>
            {featuredNodes.map((node) => (
              <ArticleThumbnail
                key={node.title}
                to={`/${sanityPage.slug.current}/${node.slug.current}`}
                title={node.title}
                media={node.heroImage}
                tag={node.resourceType?.title}
                sizes={`(max-width: ${theme.breakpoints.minSize}) 100vw, 800px`}
                loading="eager"
              />
            ))}
          </ThumbnailGrid.Featured>
        )}
        <ThumbnailGrid.Thumbnails>
          {nodes.map((node) => (
            <ArticleThumbnail
              key={node.title}
              to={`/${sanityPage.slug.current}/${node.slug.current}`}
              title={node.title}
              media={node.heroImage}
              tag={node.resourceType?.title}
              sizes={`(max-width: ${theme.breakpoints.minSize}) 100vw, 400px`}
            />
          ))}
        </ThumbnailGrid.Thumbnails>
      </ThumbnailGrid>
    </IndexPage>
  );
}

export const pageQuery = graphql`
  query ContentHubPage($limit: Int!, $skip: Int!) {
    sanityPage(_id: { eq: "contentHub" }) {
      title
      subtitle
      seoTitle
      seoDescription
      slug {
        current
      }
      heroButton {
        ...Link
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      blocks {
        ...Blocks
      }
    }
    allSanityResource(
      filter: {
        _id: { regex: "/^(?!.*drafts).*$/" }
        slug: { current: { glob: "*" } }
        resourceType: { slug: { current: { glob: "*" } } }
      }
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        ...ResourceThumbnail
      }
    }
  }
`;
